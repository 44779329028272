.container {
    position: relative;
    border: 4px solid#62c2ff;
    border-radius: 0 50% 50% 50%;
    overflow: hidden;
    transform: rotate(45deg);
    background: white;
}

.loader {
    position: relative;
    width: 200px;
    height: 200px;
    background: #2196f3;
    transform: rotate(-45deg);
    overflow: hidden;
}

.loader-before,
.loader-after {
    content: '';
    position: absolute;
    top: -200%;
    left: -50%;
    width: 200%;
    height: 200%;
    border-radius: 40%;
    background: white;
    opacity: 0.8;
    transition: 500ms;
}

.loader-before {
    opacity: 0.8;
    animation: loader 12s linear infinite;
}

.loader-after {
    opacity: 0.9;
    animation: loader2 5s linear infinite;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .loader {
      width: 130px; /* Adjust the width for smaller screens */
      height: 130px; /* Adjust the height for smaller screens */
  }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader2 {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
